import NavItem from "./NavItem";
import styled from "styled-components";
import { motion } from "framer-motion";

const Nav = styled(motion.ul)`
  display: flex;
  justify-content: center;
  gap: 3rem;
  @media screen and (max-width: 1150px) {
    display: none;
  }
`

const Navbar = () => {

  return (
    <>
      <Nav
        exit={{ opacity: 0 }}
      >
        <NavItem text='Címoldal' path='/' />
        <NavItem text='Alexról' path='/alexrol' />
        <NavItem text='Lelkek harca' path='/konyvrol' />
        <NavItem text='Blog' path='/blog' />
        <NavItem text='Kapcsolat' path='/kapcsolat' />
        <NavItem text='Megrendelés' path='/landing' />
      </Nav>
    </>
  )
}
export default Navbar