import styled from 'styled-components';
import { motion } from 'framer-motion';
import Heading from "../components/Heading";
import NavIcon from '../components/NavIcon';
import AlexIcon from '../assets/images/alex-icon.jpg';
import BookIcon from '../assets/images/book-icon.jpg';
import BlogIcon from '../assets/images/blog-icon.jpg';
import ContactIcon from '../assets/images/contact-icon.jpg';
import { Link } from 'react-router-dom';
import pdf from '../assets/GDPR_nagyalex_HU.pdf'

const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  flex-wrap: wrap;
`

const GDPR = styled(Link)`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: #ffdd99;
  font-size: 1.5rem;
`

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 3,
      staggerChildren: 0.5
    }
  }
};

const item = {
  hidden: { scale: 0 },
  visible: {
    scale: 1,
    transition: {
      scale: {
        duration: 1.2
      }
    }
  }
}

const Home = () => {

  return (
    <>
      <Heading text='Nagy Alex' />
      <Container
        variants={container}
        initial='hidden'
        animate='visible'
        exit={{opacity: 0}}
      >
        <NavIcon path='/alexrol' text='Alexról' imgUrl={AlexIcon} variants={item} />
        <NavIcon path='/konyvrol' text='Lelkek harca' imgUrl={BookIcon} variants={item} />
        <NavIcon path='blog' text='Blog' imgUrl={BlogIcon} variants={item} />
        <NavIcon path='kapcsolat' text='Kapcsolat' imgUrl={ContactIcon} variants={item} />
      </Container>
      <GDPR to={pdf} target='_blank' download>Adatvédelmi szabályzat</GDPR>
    </>
  )
}
export default Home