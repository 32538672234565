import { Link } from 'react-router-dom';
import styled from "styled-components";
import { motion } from "framer-motion";

const NavItem = styled(motion.li)`
  width: 300px;
  @media screen and (max-width: 640px) {
    width: 200px;
  }
  @media screen and (max-width: 425px) {
    width: 125px;
  }
`

const Container = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &:hover {
    div {
      color: #ffdd99;
    }
  }
`

const Text = styled.div`
  font-size: clamp(1.5rem, 4vw, 3rem);
  font-weight: bold;
  text-align: center;
`

const Image = styled(motion.img)`
  width: 100%;
  border-radius: 50%;
`

const NavIcon = ({ path, text, imgUrl, variants }) => {
  return (
    <NavItem variants={variants} >
      <Container to={path}>
        <Text>{text}</Text>
        <Image src={imgUrl} alt={text} whileHover={{ scale: 1.05 }} />
      </Container>
    </NavItem>
  )
}

export default NavIcon