import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html {
      box-sizing: border-box;
    }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: 'Special Elite';
    font-size: 12px;
    color: rgb(71, 38, 5);
    text-decoration: none;
    list-style: none;
  }
`

export default GlobalStyle;