import { useState } from "react";
import Heading from "../components/Heading";
import Navbar from "../components/Navbar";
import SmallScreenNavbar from "../components/SmallScreenNavbar";
import { Squash as Hamburger } from 'hamburger-react';
import styled from "styled-components";
import { motion } from "framer-motion";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`

const PlaceholderDiv = styled.div`
  width: 48px;
`

const BodyText = styled(motion.div)`
  width: min(95%, 880px);
  background-color: #ccb68b;
  align-self: center;
  border-radius: 2rem;
  padding: 1.8rem 1.5rem;
  margin-top: 2rem;
`;

const Paragraph = styled.p`
  font-size: clamp(1rem, 5vw, 2.2rem);
  line-height: clamp(1.2rem, 5.5vw, 3rem);
  text-align: justify;
  padding-top: 1rem;
`

const Link = styled.li`
  line-height: clamp(1.2rem, 5.5vw, 3rem);
  text-align: justify;
  padding-top: 1rem;

  a {
    font-size: clamp(1rem, 5vw, 2.2rem);
  }

  a:hover {
    font-weight: bold;
  }

`

const Contact = () => {

  const [isNavOpened, setIsNavOpened] = useState(false);

  const handleOpenNav = () => setIsNavOpened(isNavOpened => !isNavOpened);

  console.log(window.innerWidth)

  return (
    <>
      {window.innerWidth < 1151 
        ? 
        <Flex>
          <div style={{ marginTop: '4.5rem'}} onClick={() => handleOpenNav()}>
            <Hamburger rounded />
          </div>
          <Heading text='Kapcsolat' />
          <PlaceholderDiv></PlaceholderDiv>
        </Flex>
        : <Heading text='Kapcsolat' />
      }
      <Navbar />
      {isNavOpened ? <SmallScreenNavbar isNavOpened={isNavOpened} /> : null}
      <BodyText exit={{ opacity: 0 }}>
        <Paragraph>
          Kedves Látogató,
        </Paragraph>
        <Paragraph>
          köszönöm, hogy meglátogattad az oldalt.
          Ha vannak kérdéseid vagy szeretnéd megosztani az észrevételeidet, megtalálsz
        </Paragraph>
        <ul>
          <Link><a href="mailto:alexnagy0903@gmail.com">&#8594; e-mailen</a></Link>
          <Link><a href="https://www.facebook.com/nagyalexiro" target='_blank' rel="noreferrer">&#8594; Facebookon</a></Link>
          <Link><a href="https://www.facebook.com/nagyalexiro" target='_blank' rel="noreferrer">&#8594; Instagramon</a></Link>
        </ul>
        <br></br><br></br>
        <Paragraph>
          Tisztelettel
        </Paragraph>
        <Paragraph>
          Nagy Alex
        </Paragraph>
      </BodyText>
    </>
  )
}
export default Contact