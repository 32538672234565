import Thumbnail1 from '../assets/images/blog/blog-1/thumbnail.jpg';
import Thumbnail2 from '../assets/images/blog/blog-2/thumbnail.jpg';
import Thumbnail3 from '../assets/images/blog/blog-3/thumbnail.jpg';
import Thumbnail4 from '../assets/images/blog/blog-4/thumbnail.png';
import Thumbnail5 from '../assets/images/blog/blog-5/thumbnail.jpg';
import Thumbnail6 from '../assets/images/blog/blog-6/thumbnail.jpg';
import Thumbnail7 from '../assets/images/blog/blog-7/thumbnail.jpg';
import Thumbnail8 from '../assets/images/blog/blog-8/thumbnail.jpg';
import Keresztelo1 from '../assets/images/blog/blog-7/keresztelo1.jpg';
import Keresztelo2 from '../assets/images/blog/blog-7/keresztelo2.jpg';
import Keresztelo3 from '../assets/images/blog/blog-7/keresztelo3.jpg';
import Keresztelo4 from '../assets/images/blog/blog-7/keresztelo4.jpg';
import Keresztelo5 from '../assets/images/blog/blog-7/keresztelo5.jpg';
import Keresztelo6 from '../assets/images/blog/blog-7/keresztelo6.jpg';
import Konyvhet1 from '../assets/images/blog/blog-6/konyvhet1.jpeg';
import Konyvhet2 from '../assets/images/blog/blog-6/konyvhet2.jpeg';
import Konyvhet3 from '../assets/images/blog/blog-6/konyvhet3.jpeg';
import Konyvhet4 from '../assets/images/blog/blog-6/konyvhet4.jpeg';
import Konyvhet5 from '../assets/images/blog/blog-6/konyvhet5.jpeg';
import Konyvhet from '../assets/images/blog/blog-4/konyvhet.png';
import Balassa1 from '../assets/images/blog/blog-8/balassa1.jpg';
import Balassa2 from '../assets/images/blog/blog-8/balassa2.jpg';




export const blogData = [
  {
    title: 'Balassagyarmati bemutató',
    author: 'Nagy Alex',
    excerpt: 'Már több mint két hét eltelt a balassagyarmati bemutató óta, ennek ellenére még mindig erősen rezonál bennem a találkozó...',
    thumbnail: Thumbnail8,
    fullText: [
      'Már több mint két hét eltelt a balassagyarmati bemutató óta, ennek ellenére még mindig erősen rezonál bennem a találkozó...',
      'Sokan húzzák félre sajnálkozva (néha kis lenézést is elbújtatva a mosoly mögött) a szájukat, amikor a személyes találkozókat részesítem előnyben a manapság oly modern és a járvány által szinte pótolhatatlanná vált online kapcsolattartással szemben. Elismerem, lehet, hogy ebben régimódi vagyok. És elismerem, hogy a virtuális világ sok esetben megfelelő színteret nyújthat; időt, energiát takaríthat meg. Mégis! Mégis, ha egy mód van rá, akkor szemtől szemben találkozom az emberekkel. Mert a helyszínen tapasztalt reakciókat – egy rejtett mosolyt, egy alig észrevehető bólintást, egy tágra nyílt szempárt vagy éppen egy nem tetszést kifejező keresztezett kart – semmilyen kamera nem pótolhat. A keletkező energiát meg végképp nem! ',
      Balassa1,
      'A legjobb példa erre a Balassagyarmati Madách Imre Városi Könyvtárban szervezett bemutató, amit Jónás Kornélia, a helyi gimnázium irodalomtanára szervezett. Sejtettem, hogy ez a találkozó más lesz, mint a többi, mert a Lelkek harca először kerül bemutatásra gimnazistáknak. És így is lett: a majdnem negyven főt számláló közönség nagyrésze tizenéves fiatal volt.',
      'Ahogy néztem a megszeppent arcokat, a lehajtott fejeket, összeszorult a gyomrom. Tudatosodott bennem a felelősség. A „gyerekek” iránt érzett felelősség. A felelősség az iránt, amit mondok nekik. A regény írása közben ilyesmi az eszembe sem jutott...',
      'A bemutató két meglepetéssel indult. Az első: a diákok között Kornélia tanárnő kisorsolt három darab Lelkek harca kötetet. A második: A Lelkek harca regény első fejezetéből Kornélia hang- és videofelvételt készített.', 
      Thumbnail8,
      'Köszönöm tanárnő, mert ez nagyban hozzájárult ahhoz, hogy a résztvevők feloldódjanak és a bemutató jó hangulatban és kötetlenül folytatódjon. Beszélgettünk a szocializmusról, retróról, a fiatalok akkori életéről. Csevegtünk szerelemről, barátságról és eszmékről. De szó volt az írásról, a motivációról és az emberi jellemről is. Így aztán az estébe húzódott a párbeszéd. Megérintett az őszinte érdeklődés, a gyakran felcsendülő hangos nevetés, a jónéhány csillogó szempár. Tanárnőtökkel még a késő esti vacsorán is meghatódva beszélgettünk rólatok! Ha köztünk marad, elárulom: Kornélia szeret benneteket...',
      'Hálával tartozom neked, Jónás Kornélia. Nemcsak azért, mert érettségi tétellé emelted a Lelkek harcát, hanem mindenért, amit a regényért és személyemért tettél.',
      Balassa2,
      'Köszönöm nektek, balassagyarmati gimnazisták, élmény volt számomra a veletek való találkozás. A valós találkozás, amit semmilyen virtuális nem helyettesíthet. Legalábbis nekem...',
      '<a href="https://www.pantarhei.sk/356649-lelkek-harca-alex-nagy" targer="_blank">https://www.pantarhei.sk/356649-lelkek-harca-alex-nagy</a>',
      '<a href="https://shop.newlinekiado.hu/Nagy-Alex-Lelkek-harca-Hogy-hivjak-az-elvtarsnot-n" targer="_blank">https://shop.newlinekiado.hu/Nagy-Alex-Lelkek-harca-Hogy-hivjak-az-elvtarsnot-n</a>',
      'Alex :-)',
      
    ]
  },
  {
    title: 'Könyvkeresztelő',
    author: 'Nagy Alex',
    excerpt: 'Részemről ez most a köszönetnyilvánítás helye. Mert csak hálát érzek azok iránt, akik valamilyen módon részesei voltak a pénteki könyvkeresztelőnek...',
    thumbnail: Thumbnail7,
    fullText: [
      'Megvolt...',
      'És részemről ez most a köszönetnyilvánítás helye. Mert csak hálát érzek azok iránt, akik valamilyen módon részesei voltak a pénteki könyvkeresztelőnek. Köszönet mindenkinek, aki követte a neten a regényhez kapcsolódó eseményeket és a sok „lájk” -ot, elismerő kommentet.',
      Keresztelo1,
      'Köszönet a főszervezőnek, Magdinak, akinek aktív és odaadó hozzáállása nélkül nem jöhetett volna létre a bemutató. Köszönet Janinak, aki csak úgy, barátságból filmezett és fényképezett, megörökítve a rendezvényt az utókornak. Köszönet az RTVS riportereinek, Évának és Péternek, hogy az elkészített riportjaikkal országos hírré emelték a találkozót. És köszönet a Városi Könyvtár alkalmazottjainak, akik a rendezvény előtt, alatt és után szorgos munkájukkal segítették a szervezőket.',
      Keresztelo2,
      'Köszönet az előadóknak! Krisznek, aki a regényírás göröngyös útján végigvezetett, Katinak, aki módszeres pontossággal a magyar helyesírás medrébe terelte a szöveget és Enikőnek, akinek a moderálás mellett az inspirációt is köszönhetem. Köszönet húgomnak, Beának és a sógoromnak Tominak, akik sokat segítettek a rendezvény megszervezésében és a könyv népszerűsítésében.',
      Keresztelo3, 
      Keresztelo4,
      'Köszönet fiaimnak – Tominak, aki óriási türelmet tanúsított a weboldalhoz, a FB oldalhoz való igényeimhez és létrehozta az írói oldalaimat – és Petinek, aki értékes tanácsaival és tapasztalataival támogatta őt. Köszönet szeretett feleségemnek Évinek, aki az írás ideje alatt is mindig mellettem állt és ötleteivel, észrevételeivel gazdagította a könyvet.',
      Keresztelo5,
      Keresztelo6,
      'Végül, de nem utolsósorban: köszönet nektek, akik megtiszteltetek a részvételetekkel és figyelmetekkel, kedvességetekkel, jókedvetekkel szívet melengető energiával töltöttéket meg a termet.',
      'Én meg? A regény iránti elkötelezettség mellé a veletek való találkozó hozzápakolt egy jókora adag felelősséget... Úgyhogy megyek és folytatom a második rész írását!',
      'Alex :-)'
    ]
  },
  {
    title: 'Visszanézés a 93. Ünnepi Könyvhétre',
    author: 'Nagy Alex',
    excerpt: 'Már akkor kellemes érzés fogott el, amikor a Vörösmarty téri földalattiból kiszálltam és a híres Gerbeaud Cukrászda előtt állva körülnéztem...',
    thumbnail: Thumbnail6,
    fullText: [
      'Már akkor kellemes érzés fogott el, amikor a Vörösmarty téri földalattiból kiszálltam és a híres Gerbeaud Cukrászda előtt állva körülnéztem. Emberek százai lepték el a teret és érdeklődve nézelődtek az egyes könyvsátrak között.',
      Konyvhet1,
      'A Duna Korzón folytatódott a könyvkavalkád. Hihetetlen, de úgy, mint a téren, itt is minden sátornál érdeklődők! Jókedvű gyerekek, csevegő felnőttek és mosolygó nyugdíjasok. Legtöbbjük kezében könyv. Ugyanez a helyzet a kiadóm ( NewLine kiadó ) standjánál...',
      Konyvhet2,
      Konyvhet3,
      'A bőrömet a szikrázó nap sugara süti. De nem ettől borsódzik. A lelkem is beleremeg a csodálatos érzésbe: él a könyv varázsa és hódít az olvasás szelleme! Találkoztam barátokkal, írótársakkal és beszélgettem ismeretlen kíváncsiskodókkal. Köszönöm, hogy eljöttetek! 93. Ünnepi Könyvhét, Budapest – Hálás vagyok, hogy megélhettem...',
      Konyvhet4,
      Konyvhet5,
      'A Lelkek harca megkapható a szlovák Panta Rhei könyvesboltokbanés a New Line Kiadóban.',
      'Facebook oldalam: https://www.facebook.com/nagyalexiro',
      'Alex :-)'
    ]
  },
  {
    title: 'Jóhírek',
    author: 'Nagy Alex',
    excerpt: 'Ritkaság a mai rohanó világban, hogy valami a kijelölt időpont előtt elkészüljön...',
    thumbnail: Thumbnail5,
    fullText: [
      'Ritkaság a mai rohanó világban, hogy valami a kijelölt időpont előtt elkészüljön...',
      'Örömmel tudatom veletek, hogy a kiadóm május 30. helyett már május 11-én megkapta a nyomdától a Lelkek harca könyvemet. Így a regény ezen a hónap végén eljut a magyarországi könyvüzletekbe. Ami még lelkesítőbb, hogy hozzátok még hamarabb, elsőként jut el a regény, mert rögtön cselekedtünk és vasárnap elhoztunk Budapestről két nagy csomagot!',
      Thumbnail5,
      'Megtiszteltetés számomra, hogy holnaptól (kedd, május 17.) mindkét ipolysági könyvüzletben (Kníhkupectvo Šahy SNP 6 és Knihkupectvo Litera) és a Bell Stúdióban is kapható a Lelkek harca első része.',
      'Kövess a Facebookon: https://www.facebook.com/nagyalexiro',
      'Alex :-)'
    ]
  },
  {
    title: 'A 93. Ünnepi Könyvhét',
    author: 'Nagy Alex',
    excerpt: 'Ha valaki nekem két évvel ezelőtt azt mondta volna, hogy íróként megyek az ünnepi könyvhétre, hát valószínűleg jót nevettem volna rajta... Ehhez képest most örömmel osztom meg veletek a nagy hírt.',
    thumbnail: Thumbnail4,
    fullText: [
      'Ha valaki nekem két évvel ezelőtt azt mondta volna, hogy íróként megyek az ünnepi könyvhétre, hát valószínűleg jót nevettem volna rajta... ehhez képest most örömmel osztom meg veletek a nagy hírt. A kiadóm, a NewLine, lehetővé tette, hogy a regényem, és persze én magam is része legyek a 93. Ünnepi Könyvhétnek.',
      Konyvhet,
      'Hosszú volt az út, míg a teraszom székéből ide jutottam. Itt írtam a Lelkek harca nagy részét. Sokszor töprengve a mondatokon, elégedetlenkedve. Többet volt az ujjam a Delete billentyűn, mint az összes többi betűn. Ha száz évvel ezelőtt írtam volna, akkor teli lett volna körülöttem a padló összegyűrt papírlapokkal. De sokszor lazán, gördülékenyen kerültek a képzeletbeli papírlapra a megfelelő mondatok. Az érzés azonban, amikor befejeztem a napi írást, mindig egyforma volt. Békésen bámultam a zöldellő kertet és szívemet elárasztotta a belső béke.',
      'Az árusítással egybekötött kiállítást 2022.június 9-12. között rendezik Budapesten, a Vörösmarty téren. Június 11-én, szombaton délelőtt én is ott leszek a K52-es standon, várlak benneteket is.',
      'A könyhét linkje: https://www.facebook.com/unnepikonyvhet',
      'A kiadóm linkje: https://shop.newlinekiado.hu/Nagy-Alex-Lelkek-harca-Hogy-hivjak-az-elvtarsnot-n',
      'Alex :-)'
    ]
  },
  {
    title: 'A nagy nap',
    author: 'Nagy Alex',
    excerpt: 'Elérkezett a nagy nap! 2022.április 14.Sikeresen lezárultak az egyeztetések a NewLine Kiadóval. Elnyerte végleges alakját a könyv borítólapja és a regény szövegének a formája.',
    thumbnail: Thumbnail3,
    fullText: [
      'Elérkezett a nagy nap! 2022.április 14.',
      'Sikeresen lezárultak az egyeztetések a NewLine Kiadóval. Elnyerte végleges alakját a könyv borítólapja és a regény szövegének a formája. Köszönet érte a folyamat minden résztvevőjének, elsősorban: Szabó Borka grafikusnak és Gibicsárné Szabó Noémi felelős szerkesztőnek, akik nagy odaadással és szakmai hozzáértéssel végezték el az utolsó simításokat a könyvön, hogy az időben a nyomdába kerüljön.',
      'Nagy örömmel osztom meg veletek a könyv címlapjának a grafikáját!',
      Thumbnail3,
      'A Lelkek harca első része a Hogy hívják az elvtársnőt?, a nyár elején kerül a könyvüzletek polcaira és a könyváruházak weboldalaira.',
      'Magyarországon a NewLine Kiadó oldalán lesz hamarosan előrendelhető, aztán folyamatosan megrendelhetővé válik a Book24 és a Líra oldalain is. A Libri könyváruházaiban nyár elejétől lesz kapható. Szlovákiában a Panta Rhei áruház vállalta a terjesztést.',
      'Alex :-)'
    ]
  },
  {
    title: 'Hogyan készült a Lelkek harca',
    author: 'Nagy Alex',
    excerpt: 'A Lelkek harca egy kötetnek indult. Emlékszem, az elején milyen lelkes voltam. Dőlt belőlem a szó. Azaz a betű. Néhány hónap alatt megvolt négyszáz oldal és hol volt még a történet vége...',
    thumbnail: Thumbnail2,
    fullText: [
      'A Lelkek harca egy kötetnek indult. Emlékszem, az elején milyen lelkes voltam. Dőlt belőlem a szó. Azaz a betű. Néhány hónap alatt megvolt négyszáz oldal és hol volt még a történet vége! Büszke voltam magamra. Odaadtam a kéziratot a hozzám legközelebb állóknak. Dicséretek és vállveregetések. Már a kiadáson gondolkodtam, amikor egy könyvet kaptam ajándékba - Stephen King: Az írásról.',
      'Szerencsére elolvastam. Szerencsére mentort kerestem. És szerencsére megtaláltam...',
      'Nádasi Krisz vett a szárnyai alá. Elolvastam a tizenkilenc oldalas lektori véleményét... Hosszú napokig tartott, míg megemésztettem. Aztán nagy levegőt vettem és felvértezve a tanácsaival kijavítottam a szöveget.',
      'Hogy miről szól a regény? Ezt kérdezte tőlem Krisz is. Mikor belezavarodtam a körmondataimba, illedelmesen leállított. – Egy mondattal, Alex – kérte. Zavaromban visszakérdeztem: – Te hogy mondanád? Azonnal rávágta: – A szociban játszódó, nyomozással és romantikával átszőtt történet spirituális elemekkel.',
      'Megdöbbentem. Én csak a jóról, a rosszról és a szeretetről akartam írni. Valós környezetben, kitalált történettel és szereplőkkel, egy pici fantasy-val fűszerezve. Egy kicsit eljátszadozva egy-két minden embert foglalkoztató kérdéssel. Az én szemüvegemen keresztül, ahogy én képzelem ezt az egész égi-földi mesét...',
      'Aztán bólintottam. Krisz találóan fogalmazott. Erről szól a Lelkek harca első része.',
      'Bevallom, izgulok. Már csak hetek kérdése és a könyvüzletek polcain lesz a könyv. Mint egy lelkes, izgatott kisgyerek, úgy várom. Lelkes, mert megcsináltam. Izgatott, mert csak reménykedhetem, hogy tetszeni fog. Csak az nyugtat, hogy beletettem a munkát. No meg a szívemet...',
      'Alex :-)'
    ]
  },
  {
    title: '3 fő dolog, amiért könyvet írtam',
    author: 'Nagy Alex',
    excerpt: 'Ahogy közeleg a nap, amikor a Lelkek harca első része nyomdába kerül, egyre több barátommal osztom meg ezt az eddig hétpecsétes titkomat. A reakciójuk általában ugyanaz...',
    thumbnail: Thumbnail1,
    fullText: [
      'Ahogy közeleg a nap, amikor a Lelkek harca első része nyomdába kerül, egyre több barátommal osztom meg ezt az eddig hétpecsétes titkomat. A reakciójuk általában ugyanaz. A legtöbbjük csodálkozó, már-már hitetlenkedő tekintetettel rám mered és felteszi az egymilliós kérdést: Tényleg? És miért pont regényt?',
      'Eleinte váratlanul ért a kérdés. Ötöltem-hatoltam, hosszú körmondatokban okoskodtam. Idővel kiforrott bennem a pontos válasz. A következő három dolog miatt:',
      '1. A kíváncsiság. Talán furcsán hangzik, de végül is a kíváncsiság hajtott bele. Kíváncsi voltam, hogy képes vagyok-e megírni egy regény nagyságrendű történetet. Már fiatalkoromtól hordoztam magamban a késztetést. Többször neki is láttam, de egyszer sem vált annyira fontossá, hogy végig csináljam. De most, amikor papírra került az első néhány oldal, tudtam, hogy ez más. A kíváncsiság kihívássá, egyfajta belső kényszerré alakult. Ez az energia hajtott és hajt napról napra, hétről hétre.',
      '2. Az örömszerzés. Régen azt tartottam a legfontosabbnak, hogy érdekes legyen a történet, hogy megosszam a tapasztalataimat másokkal. Amikor elkezdtem a Lelkek harcát, már tudtam, hogy a puszta tapasztalatátadásnál jóval több kell. Hisz nem tankönyvet írok, hanem regényt. Próbaképp elküldtem néhány oldalt a szeretteimnek. Nem az elfogult dicséretek, hanem a folytatást követelő sürgető üzenetek győztek meg, hogy jó úton járok. Örömöt szerezni másoknak. Ez lebeg a szemem előtt. Örömöt adni, ebben az embert próbáló világban.',
      '3. A belső béke. Ez engem is meglepett. Abban reménykedtem, hogy majd egyszer, valamikor rálelek, de hogy éppen az íráson keresztül találok rá... Néhány hét intenzív írás után azt vettem észre, hogy nyugodtabb lettem. Hogy elnézően mosolygok olyan helyzetekben, amikben azelőtt dühöngtem. Hogy nem emelem meg a hangom, ha valami felidegesít. Eldöntöttem, hogy megkeresem az okát. Írás közben dúl bennem a lelkesedés és az adrenalin. Képes vagyok órákon keresztül százszázalékos fókusszal írni. Megszűnik körülöttem a világ, nem látok, nem hallok semmit. Csak a történet létezik. A végén azonban, amikor lecsukom a laptopot, beleborzong a testem és megnyugszik, békéssé válik a lelkem. Elégedett mosollyal sóhajtozom és gyermekként szeretem az életet. Köszönöm. És kívánom mindenkinek, hogy megtapasztalja...',
      'Alex :-)'
    ]
  }
]