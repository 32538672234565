import styled from 'styled-components';
import Book from '../assets/images/blog/blog-3/thumbnail.jpg';
import Alex from '../assets/images/alexrol/body-photo-2.jpg';
import FirstGen from '../assets/images/landing/first-gen.jpg';
import SecondGen from '../assets/images/landing/second-gen.jpg';
import ThirdGen from '../assets/images/landing/third-gen.jpg';
import Cassettes from '../assets/images/landing/cassettes.jpg';
import Stories from '../assets/images/landing/stories.jpg';
import Eniko from '../assets/images/landing/eniko.jpg';
import Zsuzsi from '../assets/images/landing/zsuzsi.jpg';
import Jozsef from '../assets/images/landing/jozsef.jpg';
import Virginia from '../assets/images/landing/virginia.jpg';
import Kornelia from '../assets/images/landing/kornelia.jpg';
import { Link } from 'react-router-dom';

const Home = styled(Link)`
  position: fixed;
  top: 1rem;
  right: 2rem;
  padding: 1rem 1.5rem 0.7rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #ffdd99;
  box-shadow: 4px 4px rgb(115, 62, 9);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: #ffdd99;
    background-color: rgb(115, 62, 9);
  }
`;

const View = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 3rem;
`;

const ViewOne = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 7rem;

  @media screen and (min-width: 1250px) {
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

const BookImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;

  @media screen and (min-width: 1250px) {
    width: 40%;
  }
`;

const BookImg = styled.img`
  width: 90%;
  max-width: 40rem;
  border-radius: 1rem;
  box-shadow: 8px 6px rgb(115, 62, 9);
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  width: 90%;
`;

const Links = styled.a`
  width: 16rem;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8rem;
  border-radius: 8px;
  background-color: #c30b0b;
  color: #ffdd99;
  text-align: center;
  box-shadow: 4px 4px rgb(115, 62, 9);
  &:hover {
    background-color: #ffdd99;
    color: #c30b0b;
  }
`;

const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  padding-inline: 1rem;

  @media screen and (min-width: 1250px) {
    width: 40%;
  }
`;

const Headings = styled.h1`
  font-size: clamp(2rem, 10vw, 5rem);
  word-wrap: break-word;
  text-align: center;

  &:last-child {
    width: 90%;
    color: #c30b0b;
    border: 5px dashed #c30b0b;
    text-align: center;
    padding: 3rem 2rem 2rem;
  }
`;

const ViewTwo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: 1200px) {
    align-items: flex-start;
    padding-inline: 6rem;
    gap: 3rem;
  }
`;

const Card = styled.div`
  background-color: #ccb68b;
  width: 90%;
  max-width: 1000px;
  border: 2px solid rgb(71, 38, 5);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 950px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) {
    &:nth-child(2) {
      align-self: flex-end;
    }
  }
`;

const CardThumbnail = styled.img`
  border-radius: 8px;
  border: 2px solid rgb(71, 38, 5);
  width: 100%;

  @media screen and (min-width: 750px) {
    width: 40%;
    align-self: center;
  }
  @media screen and (min-width: 950px) {
    height: 100%;
  }
`;

const CardBody = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1.5rem;
`;

const CardText = styled.li`
  font-size: 1.8rem;
  line-height: 2rem;
  list-style: disc;
  margin-left: 1.5rem;
`;

const CassettesHero = styled.div`
  width: 100vw;
  height: 300px;
  background-image: url(${Cassettes});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Taglines = styled.h1`
  font-size: clamp(2rem, 3vw, 3rem);
  word-wrap: break-word;
  text-align: center;
  padding-inline: 0.5rem;
`;

const StoriesHero = styled.div`
  width: 100vw;
  height: 500px;
  background-image: url(${Stories});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 10rem;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

const TestimonialHeading = styled.div`
  font-size: clamp(3rem, 7vw, 5rem);
  font-weight: bold;
  text-align: center;
  width: 95%;
`;

const Testimonials = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @media screen and (min-width: 1200px) {
    align-items: flex-start;
    gap: 2rem;
  }
`;

const TestimonialCard = styled.div`
  background-color: #ccb68b;
  width: 90%;
  max-width: 1000px;
  border: 2px solid rgb(71, 38, 5);
  border-radius: 8px;
  padding: 1rem;
  @media screen and (min-width: 1200px) {
    &:nth-child(2n) {
      align-self: flex-end;
    }
  }
`;

const TestimonialSignature = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const TestimonialPhoto = styled.img`
  width: 80px;
  border: 2px solid rgb(71, 38, 5);
  border-radius: 8px;
`;

const TestimonialCardText = styled.p`
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-style: italic;
`;

const Landing = () => {
  const NEW_LINE_LINK =
    'https://csakanoknekmagazin.hu/piacter/konyvek/nagy-alex-lelkek-harca?fbclid=IwAR03fgsag8Jdty1dgB7hx7yfj1S9JOEhSbpdPZ0mZLDRgLF3DnWemAsdL2A';
  const PANTA_LINK = 'https://www.pantarhei.sk/356649-lelkek-harca-alex-nagy';

  return (
    <>
      <Home to={'/'}>Haza</Home>
      <View>
        <ViewOne>
          <BookImgContainer>
            <BookImg src={Book} />
            <LinkContainer>
              <Links href={NEW_LINE_LINK} target="_blank">
                Megrendelem Magyarországból
              </Links>
              <Links href={PANTA_LINK} target="_blank">
                Megrendelem Szlovákiából
              </Links>
            </LinkContainer>
          </BookImgContainer>
          <HeadingSection>
            <Headings>Hogy a Lelkek harca-e az év könyve?</Headings>
            <Headings>Igazán remélem, hogy néhány olvasómnak igen!</Headings>
            <Headings>Jogos tehát a kérdés:</Headings>
            <Headings>Kinek szól a történet?</Headings>
          </HeadingSection>
        </ViewOne>
        <ViewTwo>
          <Card>
            <CardThumbnail src={FirstGen} />
            <CardBody>
              <CardText>
                Van itt egy sokat megélt generáció, aki imádja a retrót.
              </CardText>
              <CardText>
                Aki szívesen emlékszik vissza a régi szép időkre.
              </CardText>
              <CardText>
                Aki gyakran nosztalgiázik a 60-as, 70-es, 80-as évekről.
              </CardText>
              <CardText>
                Aki gyermekként a játszótéren vagy a grundon töltötte napjai
                nagy részét.
              </CardText>
              <CardText>
                Ha te is közénk tartozol, akkor a Lelkek harca trilógia első
                részét, a Hogy hívják az elvtársnőt? neked írtam.
              </CardText>
            </CardBody>
          </Card>
          <Card>
            <CardThumbnail src={SecondGen} />
            <CardBody>
              <CardText>
                Van itt egy fiatalabb generáció, aki imádja a retrót.
              </CardText>
              <CardText>
                Aki szereti a Beatlest, Omegát, LGT-t vagy éppen a Neotont,
                Queen-t, Guns 'N Roses-t.
              </CardText>
              <CardText>
                Aki sajnálja, hogy nem ihatott jaffát és nem élhette meg az
                űttörőtáborok korát.
              </CardText>
              <CardText>
                Aki a Retro rádiót hallgatja és kedveli a Hair-t, a Star Wars-t
                vagy az Amadeust.
              </CardText>
              <CardText>
                Ha te is közéjük tartozol, akkor a Lelkek harca trilógia első
                részét, a Hogy hívják az elvtársnőt? neked írtam.
              </CardText>
            </CardBody>
          </Card>
          <Card>
            <CardThumbnail src={ThirdGen} />
            <CardBody>
              <CardText>Van itt egy ifjú generáció, aki kíváncsi.</CardText>
              <CardText>
                Aki kíváncsi, hogy szülei milyen korban voltak annyi idősek,
                mint most ők.
              </CardText>
              <CardText>
                Aki elcsodálkozik, ha meglát egy vinil lemezt vagy egy floppyt.
              </CardText>
              <CardText>
                Aki a Stranger Things-en keresztül ismerkedett meg Kate Bush-sal
                és Madonnával.
              </CardText>
              <CardText>
                Ha te is közéjük tartozol, akkor a Lelkek harca trilógia első
                részét, a Hogy hívják az elvtársnőt? neked írtam.
              </CardText>
            </CardBody>
          </Card>
          <LinkContainer>
            <Links href={NEW_LINE_LINK} target="_blank">
              Megrendelem Magyarországból
            </Links>
            <Links href={PANTA_LINK} target="_blank">
              Megrendelem Szlovákiából
            </Links>
          </LinkContainer>
        </ViewTwo>
        <CassettesHero />
        <ViewOne>
          <BookImgContainer>
            <BookImg src={Alex} />
            <LinkContainer>
              <Links href={NEW_LINE_LINK} target="_blank">
                Megrendelem Magyarországból
              </Links>
              <Links href={PANTA_LINK} target="_blank">
                Megrendelem Szlovákiából
              </Links>
            </LinkContainer>
          </BookImgContainer>
          <HeadingSection>
            <Taglines>
              A könyv lapjain újra életre kel a 80-nas évek világa.
            </Taglines>
            <Taglines>
              Ha nosztalgiára szottyant kedved, akkor helyesen teszed, ha a
              kezedbe veszed a könyvet.
            </Taglines>
            <Taglines>
              Találsz majd szenvedélyes hölgyeket, karakán férfiakat.
            </Taglines>
            <Taglines>Találsz szerelmet, ragaszkodást és kedvességet.</Taglines>
            <Taglines>
              De találsz gyűlöletet, hatalomvágyat és ármánykodást is.
            </Taglines>
            <Taglines>
              Életre kel a Párt, az intrika és a bosszúvágy, életre kel a
              kazettás magnó, a skoda és dauer.
            </Taglines>
          </HeadingSection>
        </ViewOne>
        <StoriesHero />
        <ViewTwo>
          <TestimonialHeading>
            Olvasói vélemények a Lelkek harcáról:
          </TestimonialHeading>
          <Testimonials>
            <TestimonialCard>
              <CardBody>
                <TestimonialCardText>
                  Engem hamar megfogott az írásmód. Kezdve azzal az izgalmas,
                  éteri világgal, amit az író teremtett, és ami keretbe foglalja
                  a történetet. A nyolcvanas évek megelevenedett, ott sétáltam
                  Nettivel a dél-szlovákiai kis faluban. Beleláttam egy alpesi
                  farm mindennapjaiba. Az elején kicsit elvesztem a
                  részletekben, például a ruhák színébe, de a leírások szépek,
                  érzékletesek, hogy végül egyáltalán nem zavart. A cselekmény
                  izgalmas, magával ragadó. Szorítottam minden szereplőnek,
                  amikor bajba kerültek, mert ha nem is kedveltem meg mindenkit,
                  de az összes szereplő belső motivációját megmutatta számomra
                  az író, ami élvezetessé tette a történetet. A karakterek
                  összetettek, nem cukormázas az életük. De küzdenek a
                  mindennapok nehézségeivel, a párttal és tudtukon kívül, a
                  Lelkekkel. Várom a folytatást. :)
                </TestimonialCardText>
                <TestimonialSignature>
                  <TestimonialPhoto src={Virginia} />
                  <TestimonialCardText>
                    - Gáspár Virginia, írónő
                  </TestimonialCardText>
                </TestimonialSignature>
              </CardBody>
            </TestimonialCard>
            <TestimonialCard>
              <CardBody>
                <TestimonialCardText>
                  Rendkívül izgalmas, cselekményben gazdag könyv. Csak ajánlani
                  tudom mindenkinek. Nem tudtam letenni, már nagyon várom a
                  folytatást…
                </TestimonialCardText>
                <TestimonialSignature>
                  <TestimonialPhoto src={Zsuzsi} />
                  <TestimonialCardText>
                    - Pospíšil Zsuzsanna, gyógyszerész és vállalkozó
                  </TestimonialCardText>
                </TestimonialSignature>
              </CardBody>
            </TestimonialCard>
            <TestimonialCard>
              <CardBody>
                <TestimonialCardText>
                  Titkosrendőrség, árulás, párttagság és Pedro “zsuvi”,
                  tisztességtelenül csábító fiatal kolléganő… csomó
                  fiatalkoromra emlékeztető helyszín, bonyodalom… sági Ropovod
                  és prágai “ kachlikárna” ŠTB központ… ipolysági városház…
                  Tiszta retro … új szempontokkal… van benne minden, szoci
                  krimi, szerelem meg spirituális fantasy. Nem tudtam letenni a
                  könyvet….
                </TestimonialCardText>
                <TestimonialSignature>
                  <TestimonialPhoto src={Eniko} />
                  <TestimonialCardText>
                  - Both Enikő, kommunikációs szakember
                  </TestimonialCardText>
                </TestimonialSignature>
              </CardBody>
            </TestimonialCard>
            <TestimonialCard>
              <CardBody>
                <TestimonialCardText>Remek!</TestimonialCardText>
                <TestimonialSignature>
                  <TestimonialPhoto src={Jozsef} />
                  <TestimonialCardText>
                  - Péter József, geodéta
                  </TestimonialCardText>
                </TestimonialSignature>
              </CardBody>
            </TestimonialCard>
            <TestimonialCard>
              <CardBody>
                <TestimonialCardText>
                  A könyvedet nem tudom letenni... Imádom... ma ki is olvasom,
                  pedig csak tegnap kezdtem. A regény nagyon jó, izgatottan
                  várom a folytatást és akinek csak tudom, ajánlom.
                </TestimonialCardText>
                <TestimonialSignature>
                  <TestimonialPhoto src={Kornelia} />
                  <TestimonialCardText>
                  - Jónás Kornélia, irodalomtanár
                  </TestimonialCardText>
                </TestimonialSignature>
              </CardBody>
            </TestimonialCard>
          </Testimonials>

          <LinkContainer>
            <Links href={NEW_LINE_LINK} target="_blank">
              Megrendelem Magyarországból
            </Links>
            <Links href={PANTA_LINK} target="_blank">
              Megrendelem Szlovákiából
            </Links>
          </LinkContainer>
        </ViewTwo>
        <ViewOne>
          <BookImgContainer>
            <BookImg src={Book} />
            <LinkContainer>
              <Links href={NEW_LINE_LINK} target="_blank">
                Megrendelem Magyarországból
              </Links>
              <Links href={PANTA_LINK} target="_blank">
                Megrendelem Szlovákiából
              </Links>
            </LinkContainer>
          </BookImgContainer>
          <HeadingSection>
            <Taglines>Lehet, hogy visszavágysz ebbe a korba...</Taglines>
            <Taglines>
              Talán mert az idő megszépítette az akkori dolgokat.
            </Taglines>
            <Taglines>Talán mert maradt benned tüske abból az időből.</Taglines>
            <Taglines>
              De az is lehet, hogy csak jó érzéssel tölt el, ha egy film vagy
              egy regény segítségével újra megélheted az akkori hangulatot.
            </Taglines>
            <Taglines>
              Garantálom, hogy a Lelkek harca olvasása közben a 80-nas évek
              végén érzed majd magad és téged is magával ragad a retró történet.
            </Taglines>
          </HeadingSection>
        </ViewOne>
      </View>
    </>
  );
};
export default Landing;
