import TypeWriterEffect from 'react-typewriter-effect';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Item = styled.li`
  &>a>div>h1:hover {
    color: #ffdd99;
  }
`

const NavItem = ({ text, path, variants }) => {
  return (
    <Item variants={variants}>
      <Link to={path}>
        <TypeWriterEffect
          startDelay={100}
          text={text}
          cursorColor='rgb(71, 38, 5)'
          hideCursorAfterText={true}
          typeSpeed={250}
          textStyle={{
            fontSize: 'clamp(1.5rem, 3.5vw, 2.7rem)',
          }}
        />
      </Link>
    </Item>
  )
}
export default NavItem