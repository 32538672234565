import TypeWriterEffect from 'react-typewriter-effect';

const Heading = ({ text }) => {

  return (
    <TypeWriterEffect
      startDelay={100}
      text={text}
      cursorColor='rgb(71, 38, 5)'
      hideCursorAfterText={true}
      typeSpeed={250}
      textStyle={{
        fontSize: 'clamp(4rem, 9vw, 12rem)',
        textAlign: 'center',
        marginBottom: 'max(3%, 2rem)',
        marginTop: '5rem'
      }}
    />
  )
}
export default Heading