import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Nav = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #ccb68b;
  padding: 2rem 1.5rem;

  li a {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  @media screen and (min-width: 1151px) {
    display: none  !important;
  }
`

const variants = {
  open: { x: 1 },
  closed: { x: '-100%' }
}

const SmallScreenNavbar = ({ isNavOpened }) => {
  return (
    <Nav
      initial={{ x: '-100%'}}
      transition={{ duration: .7 }}
      variants={variants}
      animate={isNavOpened ? 'open' : 'closed'}
    >
      <li><Link to='/'>Címoldal</Link></li>
      <li><Link to='/alexrol'>Alexról</Link></li>
      <li><Link to='/konyvrol'>Lelkek harca</Link></li>
      <li><Link to='/blog'>Blog</Link></li>
      <li><Link to='/kapcsolat'>Kapcsolat</Link></li>
      <li><Link to='/landing'>Megrendelés</Link></li>
    </Nav>
  )
}
export default SmallScreenNavbar