import { useState } from "react";
import Heading from "../components/Heading";
import Navbar from "../components/Navbar";
import SmallScreenNavbar from "../components/SmallScreenNavbar";
import BlogCard from "../components/BlogCard";
import { Squash as Hamburger } from 'hamburger-react';
import styled from "styled-components";
import { motion } from "framer-motion";
import { blogData } from "../data/blog-data";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`

const PlaceholderDiv = styled.div`
  width: 48px;
`

const BodyText = styled(motion.div)`
  width: min(90%, 880px);
  background-color: #ccb68b;
  align-self: center;
  border-radius: 2rem;
  padding: 1.8rem 1.5rem;
  margin-top: 2rem;
  position: relative;
`

const Title = styled.h2`
  font-size: 3rem;
  padding: 1rem 0;
  text-align: center;
`

const Author = styled.h3`
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  padding-bottom: 2rem;
`

const Paragraph = styled.p`
  font-size: clamp(1rem, 5vw, 2.2rem);
  line-height: clamp(1.2rem, 5.5vw, 3rem);
  text-align: justify;
  padding-top: 1rem;
  &>a{
    font-size: clamp(1rem, 5vw, 2.2rem);
    line-height: clamp(1.2rem, 5.5vw, 3rem);
    text-align: justify;
    padding-top: 1rem;
    font-weight: 600;
    &:hover{
      border-bottom: 3px solid rgb(71, 38, 5);
    }
  }
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`

const Photo = styled.img`
  width: 60%;
  border-radius: 8px;
  @media screen and (max-width: 450px) {
    width: 80%
  }
`

const BackLink = styled.p`
  text-align: end;
  font-size: clamp(1rem, 5vw, 2.2rem);
  font-weight: bold;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -.5rem;
    width: 100%;
    height: 3px;
    background: rgb(71, 38, 5);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .5s;
  }
  &:hover::before {
    transform: scaleX(.1);
  }
`

const Close = styled.button`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid rgb(71, 38, 5);
  font-size: 2rem;
  font-weight: bold;
  padding-top: 5px;
  background-color: #ccb68b;
  cursor: pointer;
  box-shadow: 2px 2px rgb(71, 38, 5);
  &:hover{
    color: #ffdd99;
    background-color: rgb(71, 38, 5);
  }

  @media screen and (max-width: 1150px) {
    top: -8rem;
    right: 0;
  }
`

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.5,
    }
  }
};

const item = {
  hidden: { scale: 0 },
  visible: {
    scale: 1,
    transition: {
      scale: {
        duration: 1.5
      }
    }
  },
}

const Blog = () => {

  const [isNavOpened, setIsNavOpened] = useState(false);
  const [activeBlog, setActiveBlog] = useState(null);

  const handleOpenNav = () => setIsNavOpened(isNavOpened => !isNavOpened);

  const handleRender = (arg, index) => {
    if (arg[0] === '/') {
      return (
        <PhotoContainer key={index}>
          <Photo src={arg} />
        </PhotoContainer>
      )
    }
    
    return <Paragraph key={index} dangerouslySetInnerHTML={{__html: `${arg}`}}></Paragraph>
  }

  window.scrollTo(0, 0);

  return (
    <>
      {window.innerWidth < 1151 
        ? 
        <Flex>
          <div style={{ marginTop: '4.5rem'}} onClick={() => handleOpenNav()}>
            <Hamburger rounded />
          </div>
          <Heading text='Blog' />
          <PlaceholderDiv></PlaceholderDiv>
        </Flex>
        : <Heading text='Blog' />
      }
      <Navbar />
      {isNavOpened ? <SmallScreenNavbar isNavOpened={isNavOpened} /> : null}
      
        {!activeBlog 
        ?
        <BodyText
          variants={container}
          initial='hidden'
          animate='visible'
          exit={{opacity: 0}}
        >
          {blogData.map((blog, index) => (
          <BlogCard key={index} variants={item} blog={blog} setActiveBlog={setActiveBlog} />
          ))}
        </BodyText>
        :
        <BodyText>
          <Close onClick={() => setActiveBlog(null)}>X</Close>
          <Title>{activeBlog.title}</Title>
          <Author>írta {activeBlog.author}</Author>
          {activeBlog.fullText.map((para, index) => handleRender(para, index))}
          <BackLink onClick={() => setActiveBlog(null)}>Vissza</BackLink>
        </BodyText>
        }
    </>
  )
}
export default Blog