import { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Book from './pages/Book';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Landing from './pages/Landing';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import BookImage from './assets/images/Konnytunder.jpg';
import Typewriter from './assets/images/blog/blog-2/thumbnail.jpg';
import pdf from './assets/A_Konnytunder.pdf';
import gdpr from './assets/GDPR_nagyalex_HU.pdf';

const Main = styled.main`
  padding-top: max(3rem, 3vw);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const ModalContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.5;
`;

const Modal = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max(60%, 900px);
  height: max-content;
  background-color: #ccb68b;
  opacity: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  border-radius: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: 920px) {
    height: 90%;
    width: 90%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
`;

const ImageContainer = styled.div`
  grid-column: span 1;
  grid-row: span 1;
  height: calc(100% + 1rem);
  border-radius: 1rem;
  background-image: url(${BookImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 920px) {
    grid-row: span 1;
    background-image: url(${Typewriter});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const TextContainer = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 920px) {
    grid-row: span 3;
  }
`;

const Heading = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
  padding: 2rem;

  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
    line-height: 1.8rem;
    padding: 1.5rem 0 0;
  }
`;

const Title = styled.span`
  font-size: 2.5rem;
  font-weight: 600;

  @media screen and (max-width: 500px) {
    font-size: 1.8rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const FormInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
`;

const CheckboxInput = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 2rem;
  width: 35%;
  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
`;

const Input = styled.input`
  width: 60%;
  font-size: 1.5rem;
  padding: 0.8rem 1rem;
  border-radius: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;
`;

const Button = styled.button`
  width: 30%;
  padding: 1rem 0 0.5rem;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 8px;
  background-color: rgb(71, 38, 5);
  color: #ffdd99;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #ffdd99;
    color: rgb(71, 38, 5);
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
    margin-top: 0;
  }
`;

const Download = styled(Link)`
  width: 30%;
  padding: 1rem 0 0.5rem;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 8px;
  background-color: rgb(71, 38, 5);
  color: #ffdd99;
  cursor: pointer;
  margin-top: 1rem;
  text-align: center;

  &:hover {
    background-color: #ffdd99;
    color: rgb(71, 38, 5);
  }

  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
    margin-top: 0;
  }
`;

const Close = styled.button`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid rgb(71, 38, 5);
  font-size: 2rem;
  font-weight: bold;
  padding-top: 5px;
  background-color: #ccb68b;
  cursor: pointer;
  box-shadow: 2px 2px rgb(71, 38, 5);
  &:hover {
    color: #ffdd99;
    background-color: rgb(71, 38, 5);
  }
`;

const DownloadBtn = styled.p`
  position: fixed;
  top: 1rem;
  left: 2rem;
  padding: 1rem 1.5rem 0.7rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #ffdd99;
  box-shadow: 4px 4px rgb(115, 62, 9);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: #ffdd99;
    background-color: rgb(115, 62, 9);
  }
`;

const App = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [isNewsletter, setIsNewsletter] = useState(false);
  const [isGDPR, setIsGDPR] = useState(false);
  const [canDownload, setCanDownload] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 6500);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email } = formData;
    let data = new FormData();
    data.append('firstname', firstName);
    data.append('lastname', lastName);
    data.append('email', email);
    await fetch(`https://nagyalex.hu/api/store_mail.php`, {
      method: 'POST',
      mode: 'no-cors',
      body: data,
    });
    setCanDownload(true);
  };

  return (
    <Main>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="alexrol" element={<About />} />
          <Route path="konyvrol" element={<Book />} />
          <Route path="blog" element={<Blog />} />
          <Route path="kapcsolat" element={<Contact />} />
          <Route path="landing" element={<Landing />} />
        </Routes>
      </AnimatePresence>
      <DownloadBtn onClick={() => setShowModal(true)}>
        A Könnytündér
      </DownloadBtn>
      {showModal ? (
        <>
          <ModalContainer onClick={() => setShowModal(false)} />
          <Modal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
            exit={{ opacity: 0 }}
          >
            <Close onClick={() => setShowModal(false)}>X</Close>
            <ImageContainer />
            <TextContainer>
              <Heading>
                Kérem <br />
                Nagy Alex új novelláját,
                <br />
                <Title>A Könnytündért.</Title>
              </Heading>
              <Form
                action="https://nagyalex.hu/api/store_mail.php/"
                method="POST"
                onSubmit={handleSubmit}
              >
                <FormInput>
                  <Label htmlFor="fistname">Keresztnév:</Label>
                  <Input
                    name="firstname"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </FormInput>
                <FormInput>
                  <Label htmlFor="lastname">Vezetéknév:</Label>
                  <Input
                    name="lastname"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </FormInput>
                <FormInput>
                  <Label htmlFor="email">Email:</Label>
                  <Input
                    name="email"
                    type="email"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </FormInput>
                <CheckboxInput>
                  <input
                    type="checkbox"
                    id="newsletter"
                    value="newsletter"
                    onChange={() =>
                      setIsNewsletter((isNewsletter) => !isNewsletter)
                    }
                  />
                  <label>
                    {' '}
                    Az ingyenes anyagok elkérésével jelentkezem a hírleveledre
                    is, hiszen leiratkozhatom, amikor csak akarok.
                  </label>
                </CheckboxInput>
                <CheckboxInput>
                  <input
                    type="checkbox"
                    id="gdpr"
                    value="gdpr"
                    onChange={() => setIsGDPR((isGDPR) => !isGDPR)}
                  />
                  <label>
                    {' '}
                    Elfogadom az{' '}
                    <span style={{ textDecoration: 'underline' }}>
                      <Link to={gdpr} target="_blank" download>
                        adatvédelmi szabályzatot
                      </Link>
                    </span>{' '}
                    és a regisztrációval nagyszerű információkat kapok a Lelkek
                    harca folytatásáról és Alex további írói munkásságáról.
                  </label>
                </CheckboxInput>
                <ButtonContainer>
                  <Button type="submit" disabled={!isNewsletter || !isGDPR}>
                    Kérem
                  </Button>
                  {canDownload && (
                    <Download
                      to={pdf}
                      target="_blank"
                      download
                      onClick={() => setShowModal(false)}
                    >
                      Tessék
                    </Download>
                  )}
                </ButtonContainer>
              </Form>
            </TextContainer>
          </Modal>
        </>
      ) : null}
    </Main>
  );
};

export default App;
