import { useState } from "react";
import Heading from "../components/Heading";
import Navbar from "../components/Navbar";
import SmallScreenNavbar from "../components/SmallScreenNavbar";
import { Squash as Hamburger } from 'hamburger-react';
import styled from "styled-components";
import BookPhoto1 from '../assets/images/book/book-photo-1.jpg';
import BookPhoto2 from '../assets/images/book/book-photo-2.JPG';
import { motion } from "framer-motion";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`

const PlaceholderDiv = styled.div`
  width: 48px;
`

const BodyText = styled(motion.div)`
  width: min(95%, 880px);
  background-color: #ccb68b;
  align-self: center;
  border-radius: 2rem;
  padding: 1.8rem 1.5rem;
  margin-top: 2rem;
`;

const Paragraph = styled.p`
  font-size: clamp(1rem, 5vw, 2.2rem);
  line-height: clamp(1.2rem, 5.5vw, 3rem);
  text-align: justify;
  padding-top: 1rem;
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`

const Photo = styled.img`
  width: 60%;
  border-radius: 8px;
  @media screen and (max-width: 450px) {
    width: 80%
  }
`

const Book = () => {

  const [isNavOpened, setIsNavOpened] = useState(false)

  const handleOpenNav = () => setIsNavOpened(isNavOpened => !isNavOpened);

  return (
    <>
      {window.innerWidth < 1151 
        ? 
        <Flex>
          <div style={{ marginTop: '4.5rem'}} onClick={() => handleOpenNav()}>
            <Hamburger rounded />
          </div>
          <Heading text='Lelkek harca' />
          <PlaceholderDiv></PlaceholderDiv>
        </Flex>
        : <Heading text='Lelkek harca' />
      }
      <Navbar />
      {isNavOpened ? <SmallScreenNavbar isNavOpened={isNavOpened} /> : null}
      <BodyText exit={{ opacity: 0 }}>
        <Paragraph>
          Azt hiszem, nyugodtan leírhatom, hogy az emberek szeretik a retrót. És
          nemcsak az ötvenévesnél idősebb korosztályok. Talán érthető, hogy ebben
          a gyorsan pergő, digitális forgatagban jólesik meghallgatni a
          felejthetetlen rock bandák vagy szólóénekesek slágereit, megnézni egy
          időálló filmet, mint a Ben Hur, Hair, Híd a Kwai folyón vagy éppen a
          ballonkabátos, szivart pöfékelő Columbo felügyelőt. Vagy csak szimplán
          álmodozni a régi szép időkről. Igen, a szép időkről. Mert valami okból
          az emberi agy kiradírozza az elméből a rosszat és a szenvedést. Mintha
          rózsaszín fátyollal borítaná be a múltat őrző memóriát.
        </Paragraph>
        <PhotoContainer>
          <Photo src={BookPhoto1} />
        </PhotoContainer>
        <Paragraph>
          Főleg az idősebb generációk tagjai hajlamosak erre. Én is sokszor
          rajtakapom magam, hogy egy-egy baráti borozgatás vagy családi
          összejövetel alkalmával sztorizgatok az életemről. És pontosan ugyanazt
          teszem, amit a barátaim történeteiben, vagy akár közösségi médiában
          megosztott jegyzetben megkritizálok. Kiszínezem a mesémet. Kihagyom vagy
          bagatellizálom a rosszat és felnagyítom a jót. És ez jó érzés, mert
          boldogsággal tölt fel a nosztalgia.
        </Paragraph>
        <Paragraph>
          Aztán másnap reggel ráébredek, hogy nem kellene mindent megszépíteni.
          Mert igenis fontos, hogy levonjuk az életünkben a tanulságokat. Fontos,
          hogy pontosan tudatosítsuk, hogy mily módon küzdöttük le a sokszor
          nagyon is frusztráló akadályokat. Mert ettől is fejlődünk. Eldöntöttem,
          hogy kordában tartom magam, kizárom magamból a nosztalgia bódítását és
          megírom a fejemben már régóta formálódó történetet.
        </Paragraph>
        <PhotoContainer>
          <Photo src={BookPhoto2} />
        </PhotoContainer>
        <Paragraph>
          A Lelkek harca regény első része a nyolcvanas évek végére tekint vissza.
          Egy dél-szlovákiai fiatal család viszontagságain keresztül tart tükröt
          az akkori rendszernek. Mindez fűszerezve szerelemmel, hatalomvággyal,
          bűnüggyel, fortéllyal és megbocsátással. És, nem utolsósorban, egy
          kitalált világ fantasy-jával keretbe foglalva.
        </Paragraph>
      </BodyText>
    </>
  )
}
export default Book