import { useState } from 'react';
import Heading from '../components/Heading';
import Navbar from '../components/Navbar';
import SmallScreenNavbar from '../components/SmallScreenNavbar';
import { Squash as Hamburger } from 'hamburger-react';
import styled from 'styled-components';
import BodyPhoto1 from '../assets/images/alexrol/body-photo-1.jpg';
import BodyPhoto2 from '../assets/images/alexrol/body-photo-2.jpg';
import { motion } from 'framer-motion';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`;

const PlaceholderDiv = styled.div`
  width: 48px;
`;

const BodyText = styled(motion.div)`
  width: min(95%, 880px);
  background-color: #ccb68b;
  align-self: center;
  border-radius: 2rem;
  padding: 1.8rem 1.5rem;
  margin-top: 2rem;
`;

const Paragraph = styled.p`
  font-size: clamp(1rem, 5vw, 2.2rem);
  line-height: clamp(1.2rem, 5.5vw, 3rem);
  text-align: justify;
  padding-top: 1rem;
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`

const Photo = styled.img`
  width: 60%;
  border-radius: 8px;
  @media screen and (max-width: 450px) {
    width: 80%
  }
`

const About = () => {
  const [isNavOpened, setIsNavOpened] = useState(false);

  const handleOpenNav = () => setIsNavOpened((isNavOpened) => !isNavOpened);

  return (
    <>
      {window.innerWidth < 1151 ? (
        <Flex>
          <div style={{ marginTop: '4.5rem'}} onClick={() => handleOpenNav()}>
            <Hamburger rounded />
          </div>
          <Heading text="Alexról" />
          <PlaceholderDiv></PlaceholderDiv>
        </Flex>
      ) : (
        <Heading text="Alexról" />
      )}
      <Navbar />
      {isNavOpened ? <SmallScreenNavbar isNavOpened={isNavOpened} /> : null}
      <BodyText exit={{ opacity: 0 }}>
        <Paragraph>
          Fiatal koromban leginkább a matematika vonzott, de az irodalom is
          előkelő helyet foglalt el a rangsoromban. Szerettem olvasni. És ez a
          mai napig így van. Steinbeck, Dumas, Stendhal vagy éppen Jókai,
          Gárdonyi, József Attila gyakran volt vendég nálam...
        </Paragraph>
        <Paragraph>
          Több társammal ellentétben a fogalmazás órákat is szerettem. A
          gimnáziumi évek alatt megesett, hogy egy-egy jól sikerült esszé után
          arról álmodoztam, hogy író leszek. Többször bele is kezdtem az írásba,
          de néhány kézzel írott oldalnál sosem jutottam tovább. Talán lusta
          voltam, talán nem volt hozzá elegendő önbizalmam. Tény, hogy
          programozó matematikus lettem, nem író...
        </Paragraph>
        <PhotoContainer>
          <Photo src={BodyPhoto1} />
        </PhotoContainer>
        <Paragraph>
          Aztán rám is várt a nagybetűs élet. Még a szociban indult. Semmi
          különös, egy-két kiábrándító pofonon kívül. Már éppen belerázódtam volna
          a szocialista valóságba, amikor jött a forradalom. A bársonyos. A jövőbe
          mutató. A szabadságot ígérő. És még folytathatnám...
        </Paragraph>
        <Paragraph>
          A saját lábamra álltam, vállalkozni kezdtem. Ebben a hosszú évekig tartó
          forgatagban az írásról el is feledkeztem. És ahogy ez már a vállalkozói
          életben lenni szokott – de persze nemcsak abban - voltak nagyon sikeres
          időszakok, viszont komoly veszteségek is. Az élet többször térdre
          kényszerített, de soha nem adtam fel, megráztam magam, felálltam és
          újból kezdtem. Nem volt könnyű, de szép volt.
        </Paragraph>
        <PhotoContainer>
          <Photo src={BodyPhoto2} />
        </PhotoContainer>
        <Paragraph>
          Visszatekintve tisztán látom, hogy mit kellett megtanulnom. Hogy hová
          sodortak a történtek. Végül is most már minden olyan egyszerűnek tűnik:
          hiszek a kézfogásban, az adott szóban, ami számomra szent. Hiszek a
          jóban és a szeretetben. Hiszek a rossz erejében, de abban is, hogy az
          ember képes felülkerekedni rajta. Hiszek abban, hogy az számít, ami a
          szívünkben van. Hiszek a kapcsolat és a megbocsátás felemelő erejében.
        </Paragraph>
        <Paragraph>
          Most jött el a pillanat, hogy ezt az egészet könyv formájában megosszam
          veled. A tapasztalataimat, az elveimet, az érzéseimet. És az őket
          keretbe foglaló lelki világomat. Deres hajú író lettem...
        </Paragraph>
      </BodyText>
    </>
  );
};
export default About;
