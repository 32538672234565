import styled from "styled-components";
import { motion } from "framer-motion";

const Card = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 2px solid rgb(71, 38, 5);
  border-radius: 8px;
  padding: 1rem;
  gap: 1rem;
  height: 300px;
  margin-bottom: 2rem;

  @media screen and (max-width: 740px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: 600px;
  }
`

const Thumbnail = styled(motion.div)`
  grid-column-start: 1;
  background-image: url(${props => props.thumbnail});
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;

  @media screen and (max-width: 740px) {
    grid-row: span 1;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  grid-column: span 2;
  position: relative;

  @media screen and (max-width: 740px) {
    grid-row-start: 2;
  }
`

const Title = styled.p`
  font-weight: bold;
  font-size: 2rem;
  margin-top: 1rem;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -.5rem;
    width: 100%;
    height: 3px;
    background: rgb(71, 38, 5);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform .5s;
  }
  &:hover::before {
    transform: scaleX(1);
  }
`

const Text = styled.p`
  font-size: 1.8rem;
  line-height: 2rem;
`

const BlogCard = ({ variants, blog, setActiveBlog }) => {

  return (
    <Card variants={variants}>
      <Thumbnail onClick={() => setActiveBlog(blog)} thumbnail={blog.thumbnail} />
      <Body>
        <Title onClick={() => setActiveBlog(blog)}>{blog.title}</Title>
        <Text>írta {blog.author}</Text>
        <Text>{blog.excerpt}</Text>
      </Body>
    </Card>
  )
}
export default BlogCard